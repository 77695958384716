import { atom } from 'recoil';

const THIRTY_MINUTES = 30 * 60 * 1000; // 30 phút tính bằng milliseconds

export const cmsBlocksState = atom<{
    [key: string]: {
        data: any;
        timestamp: number;
    };
}>({
    key: 'cmsBlocksState',
    default: {}, // giá trị mặc định khi không có dữ liệu trong localStorage
    effects_UNSTABLE: [
        ({ setSelf, onSet }) => {
            if (typeof window !== 'undefined') {
                const now = new Date().getTime();
                const savedBlocks = JSON.parse(localStorage.getItem('cmsBlocksState') || '{}');
                const validBlocks: Record<string, { data: any; timestamp: number }> = {};

                // Kiểm tra từng block để xem đã hết 30 phút chưa
                Object.keys(savedBlocks).forEach((blockId) => {
                    const { data, timestamp } = savedBlocks[blockId];

                    // Nếu block còn hạn 30 phút thì giữ lại
                    if (now - timestamp < Number(process.env.NEXT_PUBLIC_CMS_BLOCK_EXPIRE)) {
                        validBlocks[blockId] = { data, timestamp };
                    }
                });
                // Cập nhật atom với các block hợp lệ
                setSelf(
                    Object.keys(validBlocks).reduce(
                        (acc, blockId) => {
                            acc[blockId] = {
                                ...validBlocks[blockId]
                            };
                            return acc;
                        },
                        {} as { [key: string]: { data: any; timestamp: number } }
                    )
                );

                // Khi dữ liệu thay đổi, lưu lại vào localStorage
                onSet((newBlocks) => {
                    const updatedBlocks = { ...validBlocks };

                    // Cập nhật dữ liệu và timestamp cho các block mới
                    Object.keys(newBlocks).forEach((blockId) => {
                        const block = newBlocks[blockId];
                        updatedBlocks[blockId] = {
                            ...block,
                            timestamp: updatedBlocks[blockId]?.timestamp || now // Giữ timestamp cũ nếu block đã có
                        };
                    });
                    // Lưu các block hợp lệ vào localStorage
                    localStorage.setItem('cmsBlocksState', JSON.stringify(updatedBlocks));
                });
            }
        }
    ]
});
