import { useQuery } from '@apollo/client';
import { useRecoilState } from 'recoil';
import { cmsBlocksState } from '~/services/blocks';
import { QUERY_GET_CMS_BLOCKS } from '~/data/home';

const useCmsBlocks = (blockIds: string[]) => {
    const [cmsBlocks, setCmsBlocks] = useRecoilState(cmsBlocksState);
    const { loading, error } = useQuery(QUERY_GET_CMS_BLOCKS, {
        variables: { identifiers: blockIds },
        skip: Boolean(blockIds.every((id) => (cmsBlocks as any)[id])), // Skip fetching if all blockIds are already cached
        onCompleted: (data) => {
            if (data && data.cmsBlocks) {
                // Update the Recoil state with the new block data
                setCmsBlocks((prev: any) => {
                    const updatedBlocks = { ...prev };
                    blockIds.forEach((id) => {
                        updatedBlocks[id] = {
                            data: data.cmsBlocks.items?.find((item: any) => item.identifier === id),
                            timestamp: Date.now()
                        };
                    });
                    return updatedBlocks;
                });
            }
        },
        context: { fetchOptions: { method: 'GET' } }
    });

    return {
        cmsBlocks: blockIds.reduce(
            (acc, id) => {
                acc[id] = cmsBlocks[id]?.data;
                return acc;
            },
            {} as Record<string, any>
        ),
        loading,
        error
    };
};

export default useCmsBlocks;
