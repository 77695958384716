import Link from "next/link";
import { useRouter } from "next/router";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import { UserAtom } from "~/services/user";
import Avatar from "../Avatar";
import Button from "../Button";
import UserIcon from "~/assets/svgs/user.svg";

const UserButton: FC = () => {
  const router = useRouter();
  const currentUser = useRecoilValue(UserAtom.currentUser);

  return currentUser ? (
    <a href="/profile/information" className="inline-flex items-center">
      <Avatar
        size="xsmall"
        name={currentUser.firstname || currentUser.lastname || "-"}
      />
    </a>
  ) : (
    <Button
      prefixIcon={<UserIcon width={24} height={24} alt="user" />}
      shape="square"
      type="borderless"
      onClick={() => {
        router.push("/sign-in");
      }}
      aria-label="Sign in"
    ></Button>
  );
};

export default UserButton;
